<template>
  <div>
    <top-navigation-component/>
    <div class='p-24'>
      <div class='hng m-auto'>
        <div class='flex mt-8 text-gray-700'>
          <router-link class='flex'
            :to='{ name: "home" }'>
            <i class='icon'>arrow_back</i>
          </router-link>
          <div class='ml-16 text-lg font-bold'>{{ $t('pages.how-esim-works.80fb8648') }}</div>
        </div>
        <div class='mt-48'>
          <img class='yea mx-auto w-full'
            src='images/d689.png'>
          <img class='yea my-48 mx-auto w-full'
            src='images/b37a.png'>
          <img class='yea my-48 mx-auto w-full'
            src='images/2fc7.png'>
          <img class='yea my-48 mx-auto w-full'
            src='images/c5a1.png'>
          <img class='yea my-48 mx-auto w-full'
            src='images/9b37.png'>
          <img class='tim mx-auto w-full'
            src='images/949e.png'>
        </div>
      </div>
    </div>
    <bottom-navigation-component/>
  </div>
</template>

<script>
  import BottomNavigation from '@/components/bottom-navigation'
  import TopNavigation from '@/components/top-navigation'

  export default {
    components: {
      'bottom-navigation-component': BottomNavigation,
      'top-navigation-component': TopNavigation
    }
  }
</script>

<style lang='scss' scoped>
  .hng {
    max-width: 480px;
  }

  .yea {
    max-width: 360px;
  }

  .tim {
    max-width: 240px;
  }
</style>
